<template>
    <div class="col p-4">
        <form @submit.prevent="onSubmit">
            <div class="row mb-3">
                <label for="form-apiKey" class="col-sm-3 col-form-label text-end">API Key</label>
                <div class="col-sm-9">
                    <input type="text" v-model="apiKey" placeholder="sk_xxxx" class="form-control" id="form-apiKey">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="form-saveLocal" v-model="saveKey">
                        <label class="form-check-label" for="form-saveLocal">
                            Save the key in your local storage (it won't be shared or exported)
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="form-source" class="col-sm-3 col-form-label text-end">
                    Source
                    <a href="https://docs.pdfshift.io/#convert-body-source" title="View this parameter on the documentation" target="_blank" class="text-body-secondary ms-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#adb5bdbf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>
                    </a>
                </label>
                <div class="col-sm-9">
                    <textarea class="form-control" id="form-source" rows="5" v-model="form.source" placeholder="Either starts by http://... or a raw &lt;html&gt;" data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false"></textarea>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="form-sandbox" v-model="form.sandbox">
                        <label class="form-check-label" for="form-sandbox">Sandbox mode</label>
                        <a href="https://docs.pdfshift.io/#convert-body-sandbox" title="View this parameter on the documentation" target="_blank" class="text-body-secondary ms-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#adb5bdbf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>
                        </a>
                    </div>
                    <p><small v-if="!form.sandbox" class="ms-2 inline-block text-danger">Disables watermarking. Credits will be deducted for each conversion.</small></p>
                </div>
            </div>

            <div v-for="item in selectedProperties" :key="item.key" class="row mb-3">
                <template v-if="item.type === 'dict'">
                    <label class="col-sm-3 col-form-label text-end" :data-bs-title="item.help" :data-bs-toggle="item.help ? 'tooltip' : null" :data-bs-placement="item.help ? 'top' : null">
                        {{ item.label }}
                    </label>
                    <div class="col-sm-9">
                        <div v-for="field in item.fields" :key="field" class="row mb-3">
                            <field-item :item="field" v-model="form[item.key][field.key]" />
                        </div>
                    </div>
                </template>
                <field-item v-else :item="item" v-model="form[item.key]" />
            </div>

            <hr class="mb-2" />

            <div class="row mb-2">
                <div class="dropdown dropup col-sm-9 offset-sm-3">
                    <ul class="dropdown-menu" ref="dropdown">
                        <li v-for="(option, index) in availableOptions" :key="index" @click="addOption(option)">
                            <a href="javascript:;" class="dropdown-item" :class="{'active': (index === selectedIndex)}">{{ option }}</a>
                        </li>
                    </ul>
                    <input type="text" name="search" v-model="filter" autocomplete="off" spellcheck="false" placeholder="+ Add a parameter" class="form-control" data-bs-toggle="dropdown" @keydown="navigateFilter" ref="dropdownTrigger" />
                </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-4">
                <div class="d-flex flex-column offset-sm-3">
                    <button type="button" class="btn btn-secondary" :disabled="saveDisabled" :class="{'btn-disabled': saveDisabled}" @click="saveForm">Save and share the form</button>
                    <small v-if="apiKey">(Your API key won't be shared.)</small>
                </div>
                <button type="submit" class="btn btn-primary" :disabled="isSending" :class="{'btn-disabled': isSending}">Generate a PDF</button>
            </div>
        </form>
    </div>
</template>

<script>
import FieldItem from '@/components/FieldItem'

export default {
    components: { FieldItem },
    emits: ['update', 'submit', 'save'],
    data () {
        return {
            apiKey: null,
            saveKey: false,
            filter: null,
            selectedIndex: 0,
            disableEvent: false,
            isSending: false,
            saveCounter: 0,
            counterTimeout: null,
            options: {
                encode: {
                    type: 'boolean',
                    default: false,
                    label: 'Encode the result as Base64',
                    help: 'Will return the generated PDF in Base64 encoded format, instead of raw.'
                },
                filename: {
                    type: 'text',
                    label: 'Filename',
                    placeholder: 'export.pdf',
                    help: 'Name of the destination file'
                },
                s3_destination: {
                    type: 'text',
                    label: 'S3 Destination',
                    placeholder: 's3://bucket/path/to/folder/',
                    help: 'Path to your S3 bucket, in order to save the converted PDF directly into your AWS S3 account.'
                },
                format: {
                    type: 'text',
                    label: 'Format',
                    values: ['letter', 'legal', 'tabloid', 'ledger', 'a0', 'a1', 'a2', 'a3', 'a4', 'a5'],
                    help: 'Format of the document. You can either use the standard values (Letter, Legal, Tabloid, Ledger, A0, A1, A2, A3, A4, A5) or a custom {width}x{height} value. For {width} and {height}, you can indicate the following units: in, cm, mm.'
                },
                css: {
                    type: 'textarea',
                    label: 'Custom CSS',
                    placeholder: 'Either an URL or a raw CSS',
                    help: 'Will append this CSS styles to the document before saving it. Can be an URL or a String of CSS rules.'
                },
                javascript: {
                    type: 'textarea',
                    label: 'Custom JS',
                    placeholder: 'Either an URL or a raw JS',
                    help: 'Will execute the given Javascript before saving the document. Can be an URL or a String of JS code.'
                },
                wait_for: {
                    type: 'text',
                    label: 'Wait for',
                    placeholder: 'waitForAllImageLoaded',
                    help: 'Name of a function available globally. When present, PDFShift will wait for this function to return a truthy value (true, 1, a string, etc) or up to 30 seconds, then proceed to the conversion.'
                },
                wait_for_network: {
                    type: 'boolean',
                    default: false,
                    label: 'Wait for network'
                },
                ignore_long_polling: {
                    type: 'boolean',
                    default: false,
                    label: 'Ignore long polling'
                },
                disable_images: {
                    type: 'boolean',
                    default: false,
                    label: 'Disable images',
                    help: 'Images will not be included in the final document.'
                },
                disable_javascript: {
                    type: 'boolean',
                    default: false,
                    label: 'Disable javascript',
                    help: 'Will not execute the javascript at all in the document.',
                },
                disable_links: {
                    type: 'boolean',
                    default: false,
                    label: 'Disable links',
                    help: 'The link in the document will not point anywhere.'
                },
                disable_backgrounds: {
                    type: 'boolean',
                    default: false,
                    label: 'Disable backgrounds',
                    help: 'The final document will not have the background images.'
                },
                grayscale: {
                    type: 'boolean',
                    default: false,
                    label: 'Grayscale'
                },
                landscape: {
                    type: 'boolean',
                    default: false,
                    label: 'Landscape mode',
                    help: 'Will set the view in landscape mode instead of portrait.'
                },
                use_print: {
                    type: 'boolean',
                    default: false,
                    label: 'Use print mode',
                    help: 'Use the print stylesheet instead of the general one.'
                },
                remove_blank: {
                    type: 'boolean',
                    default: false,
                    label: 'Remove Blank',
                    help: 'Remove the last page if it is considered empty.'
                },
                pages: {
                    type: 'text',
                    label: 'Pages',
                    placeholder: '1-5 or 1,2,6',
                    help: 'Pages to print. Can be one number (3), a range (1-5), a list (4,5,6) or a combination of both (1-3,6,7). If the number is higher than the real number of pages, that number will be ignored.'
                },
                raise_for_status: {
                    type: 'boolean',
                    default: false,
                    label: 'Raise for Status',
                    help: 'Will stop the conversion if the status_code from the given source is not 2XX. Default is False'
                },
                delay: {
                    type: 'number',
                    label: 'Delay',
                    help: 'In milliseconds. Will wait for this duration before capturing the document. Up to 10 seconds max.',
                    placeholder: '1500',
                    min: 0,
                    max: 10000
                },
                timeout: {
                    type: 'number',
                    label: 'Timeout',
                    placeholder: '5',
                    help: 'Will kill the page loading at a specified time without stopping with a TimeoutError. Value in seconds.',
                    min: '0',
                    max: '900'
                },
                webhook: {
                    type: 'url',
                    label: 'Webhook',
                    placeholder: 'https://...',
                    help: 'An URL where we will send a POST request containing a JSON body similar to when you use the filename parameter. The JSON response will contain a URL key that points to your file, stored on Amazon S3.'
                },
                viewport: {
                    type: 'text',
                    label: 'Viewport',
                    placeholder: '1200x1024'
                },
                zoom: {
                    type: 'number',
                    label: 'Zoom',
                    placeholder: '',
                    min: '0.1',
                    max: '2',
                    step: '0.1',
                    help: 'A value between 0 and 2. Allows you to increase the zoom in the document for specific purposes. 1 is the default zoom, lower is smaller, higher is bigger.'
                },
                margin: {
                    type: 'text',
                    label: 'Margin',
                    placeholder: '5p 10px 15px 20px',
                    help: 'Empty spaces between the outer and the beginning of the content. See the Margin section for more details.'
                },
                auth: {
                    type: 'dict',
                    label: 'Auth',
                    help: 'Object containing username and password for accessing password-protected content.',
                    fields: {
                        username: {
                            type: 'text',
                            label: 'Username',
                            key: 'username',
                            placeholder: 'Username'
                        },
                        password: {
                            type: 'password',
                            label: 'Password',
                            key: 'password',
                            placeholder: 'Password'
                        }
                    }
                },
                header: {
                    type: 'dict',
                    label: 'Header',
                    fields: {
                        source: {
                            type: 'textarea',
                            key: 'source',
                            label: 'Source',
                            placeholder: 'Either an URL or a raw HTML'
                        },
                        height: {
                            type: 'number',
                            key: 'height',
                            label: 'Height',
                            placeholder: '150px'
                        },
                        start_at: {
                            type: 'number',
                            key: 'start_at',
                            label: 'Start at',
                            min: 1
                        }
                    }
                },
                footer: {
                    type: 'dict',
                    label: 'Footer',
                    fields: {
                        source: {
                            type: 'textarea',
                            key: 'source',
                            label: 'Source',
                            placeholder: 'Either an URL or a raw HTML'
                        },
                        height: {
                            type: 'number',
                            key: 'height',
                            label: 'Height',
                            placeholder: '150px'
                        },
                        start_at: {
                            type: 'number',
                            key: 'start_at',
                            label: 'Start at',
                            min: 1
                        }
                    }
                },
                protection: {
                    type: 'dict',
                    label: 'Protection',
                    fields: {
                        author: {
                            type: 'text',
                            key: 'author',
                            label: 'Author',
                            placeholder: 'Author name'
                        },
                        user_password: {
                            type: 'text',
                            key: 'user_password',
                            label: 'User password',
                            placeholder: 'User password'
                        },
                        owner_password: {
                            type: 'text',
                            key: 'owner_password',
                            label: 'Owner pasword',
                            placeholder: 'Owner password'
                        },
                        no_print: {
                            type: 'boolean',
                            key: 'no_print',
                            default: false,
                            label: 'No print'
                        },
                        no_copy: {
                            type: 'boolean',
                            key: 'no_copy',
                            default: false,
                            label: 'No copy'
                        },
                        no_modify: {
                            type: 'boolean',
                            key: 'no_modify',
                            default: false,
                            label: 'No modify'
                        }
                    }
                },
                /*
                http_headers: {
                    type: 'keyvalue',
                    label: 'HTTP Headers'
                },
                metadata: {
                    type: 'keyvalue',
                    label: 'Metadata'
                },
                cookies: [], * { name: null, value: null, secure: null, http_only: null } *
                watermark: {
                    source: null,

                    image: null,

                    text: null,
                    font_size: 16,
                    font_family: 'Helvetica',
                    font_color: '000000',
                    font_opacity: 100,
                    font_bold: false,
                    font_italic: false,

                    offset_x: 'center',
                    offset_y: 'center',
                    rotate: -45
                }
                */
            },
            form: {
                source: null,
                sandbox: true
            }
        }
    },
    computed: {
        availableOptions () {
            let result = Object.keys(this.options)
            if (this.filter) {
                const filter = this.filter.toLowerCase()
                result = result.filter(x => x.indexOf(filter) > -1)
            }

            const selecteds = Object.keys(this.form)
            return result.filter(x => selecteds.indexOf(x) === -1).sort()
        },
        selectedProperties () {
            return Object.keys(this.form).filter(x => ['source', 'sandbox'].indexOf(x) === -1).map(x => ({ key: x, ...this.options[x] }))
        },
        saveDisabled () {
            if (this.isSending) return true
            if (this.saveCounter > 5) return true
            return false
        }
    },
    watch: {
        form: {
            deep: true,
            immediate: true,
            handler (to) {
                if (!this.disableEvent) this.$emit('update', to)
                this.isSending = !to.source
            }
        },
        saveKey (to) {
            if (to) {
                if (this.apiKey) {
                    localStorage.setItem('pdfshift.apiKey', this.apiKey)
                }
            } else {
                localStorage.removeItem('pdfshift.apiKey')
            }
        },
        apiKey (to) {
            if (this.saveKey) {
                localStorage.setItem('pdfshift.apiKey', to)
            }
        }
    },
    created () {
        try {
            const key = localStorage.getItem('pdfshift.apiKey')
            if (key) {
                this.apiKey = key
                this.saveKey = true
            }
        } catch (e) {
            // pass
        }
    },
    methods: {
        test ($event) {
            if ($event.key === 'Enter' && $event.ctrlKey) {
                this.onSubmit()
            }
        },
        update (form) {
            this.disableEvent = true
            this.form = form
            this.$nextTick(() => { this.disableEvent = false })
        },
        addOption (key) {
            if ('default' in this.options[key]) {
                let defaultValue = this.options[key].default
                if (this.options[key].type === 'boolean') {
                    defaultValue = !defaultValue
                }
                this.form[key] = defaultValue
            } else if (this.options[key].type === 'dict') {
                this.form[key] = {}
            } else {
                this.form[key] = null
            }

            this.filter = null
        },
        showDropdown () {
            if (!this.$refs.dropdownTrigger) return
            // eslint-disable-next-line no-undef
            bootstrap.Dropdown.getOrCreateInstance(this.$refs.dropdownTrigger).show()
        },
        hideDropdown () {
            if (!this.$refs.dropdownTrigger) return
            // eslint-disable-next-line no-undef
            bootstrap.Dropdown.getOrCreateInstance(this.$refs.dropdownTrigger).hide()
        },
        navigateFilter ($event) {
            let selection = 0
            const isVisible = this.$refs.dropdown.classList.contains('show')
            let showDropdown = null
            switch ($event.key) {
                case 'Enter':
                    $event.stopImmediatePropagation()
                    $event.preventDefault()
                    showDropdown = false
                    if (this.availableOptions.length === 0) return
                    if (this.selectedIndex < this.availableOptions.length - 1) {
                        selection = this.selectedIndex
                    }
                    this.addOption(this.availableOptions[selection])
                    break
                case 'ArrowUp':
                    showDropdown = true
                    if (!isVisible) {
                        break
                    }
                    if (this.selectedIndex === 0) {
                        this.selectedIndex = this.availableOptions.length - 1
                        this.scrollIntoView('end')
                    } else {
                        this.selectedIndex -= 1
                        this.scrollIntoView('start')
                    }

                    break
                case 'ArrowDown':
                    showDropdown = true
                    if (!isVisible) {
                        break
                    }

                    if (this.selectedIndex + 1 >= this.availableOptions.length) {
                        this.selectedIndex = 0
                        this.scrollIntoView('start')
                    } else {
                        this.selectedIndex += 1
                        this.scrollIntoView('end')
                    }
                    break
                default:
                    if (this.availableOptions.length === 0) this.hideDropdown()
                    else this.showDropdown()
                    return
            }

            if (showDropdown === true && !isVisible) this.showDropdown()
            else if (showDropdown === false && isVisible) this.hideDropdown()
        },
        scrollIntoView (block) {
            this.$nextTick(() => {
                const activeEl = this.$refs.dropdown.querySelector('.active')
                const activeElRect = activeEl.getBoundingClientRect()
                const parentRect = this.$refs.dropdown.getBoundingClientRect()

                let scroll = false
                if (block === 'end') {
                    scroll = activeElRect.bottom > parentRect.bottom
                } else {
                    scroll = activeElRect.top < parentRect.top
                }

                if (scroll) {
                    this.$refs.dropdown.querySelector('.active').scrollIntoView({ block })
                }
            })
        },
        onSubmit () {
            if (this.isSending) return false
            this.isSending = true
            this.$emit('submit', this.form)
        },
        saveForm () {
            if (this.saveCounter === 0) {
                if (this.counterTimeout) clearTimeout(this.counterTimeout)

                this.counterTimeout = setTimeout(() => {
                    this.saveCounter = 0
                }, 60000) // We reset the counter in a minute
            }

            this.saveCounter += 1
            this.$emit('save', this.form)
        }
    }
}
</script>

<style scoped>
.form-switch input, .form-switch label { cursor: pointer }
.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}
</style>
