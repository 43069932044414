<template>
    <template v-if="['text', 'number', 'url', 'password'].indexOf(item.type) > -1">
        <label :for="`form-${item.key}`" class="col-sm-3 col-form-label text-end" :data-bs-title="item.help" :data-bs-toggle="item.help ? 'tooltip' : null" :data-bs-placement="item.help ? 'top' : null">
            {{ item.label }}
            <a :href="'https://docs.pdfshift.io/#convert-body-' + item.key" title="View this parameter on the documentation" target="_blank" class="text-body-secondary ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#adb5bdbf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>
            </a>
        </label>
        <div class="col-sm-9">
            <input :type="item.type" :min="item.min" :step="item.step" :max="item.max" :placeholder="item.placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" class="form-control" :id="`form-${item.key}`" ref="input">
        </div>
    </template>
    <template v-else-if="item.type === 'textarea'">
        <label :for="`form-${item.key}`" class="col-sm-3 col-form-label text-end" :data-bs-title="item.help" :data-bs-toggle="item.help ? 'tooltip' : null" :data-bs-placement="item.help ? 'top' : null">
            {{ item.label }}
            <a :href="'https://docs.pdfshift.io/#convert-body-' + item.key" title="View this parameter on the documentation" target="_blank" class="text-body-secondary ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#adb5bdbf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>
            </a>
        </label>
        <div class="col-sm-9">
            <textarea class="form-control" :id="`form-${item.key}`" rows="5" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :placeholder="item.placeholder" data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false" ref="input"></textarea>
        </div>
    </template>
    <template v-else-if="item.type === 'boolean'">
        <div class="offset-sm-3 col-sm-9">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" :id="`form-${item.key}`" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)" ref="input">
                <label class="form-check-label" :for="`form-${item.key}`" :data-bs-title="item.help" :data-bs-toggle="item.help ? 'tooltip' : null" :data-bs-placement="item.help ? 'top' : null">{{ item.label }}</label>
                <a :href="'https://docs.pdfshift.io/#convert-body-' + item.key" title="View this parameter on the documentation" target="_blank" class="text-body-secondary ms-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#adb5bdbf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>
                </a>
            </div>
        </div>
    </template>
    <template v-else-if="item.type === 'keyvalue'">
        <label :for="`form-${item.key}`" class="col-sm-3 col-form-label text-end">
            <a :href="'https://docs.pdfshift.io/#convert-body-' + item.key" title="View this parameter on the documentation" target="_blank">{{ item.label }}</a>
        </label>
        <div class="col-sm-9">Unsupported yet ...</div>
    </template>
</template>

<script>
export default {
    props: {
        item: {
            required: true,
            type: Object
        },
        modelValue: {}
    },
    emits: ['update:modelValue'],
    mounted () {
        if (this.$refs.input) {
            this.$refs.input.focus()
        }

        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(x => {
            // eslint-disable-next-line no-undef
            new bootstrap.Tooltip(x, {placement: 'top', fallbackPlacements: ['top']})
        })
    }
}
</script>
