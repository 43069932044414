<template>
    <div>
        <header id="header" class="fixed-heading">
            <div class="heading">
                <div class="container">
                    <div itemtype="http://schema.org/SiteNavigationElement" class="rrow">
                        <div class="column">
                            <a href="https://pdfshift.io/" aria-current="page" title="Return to our Homepage" itemprop="name" class="brand nuxt-link-exact-active nuxt-link-active">
                                <svg viewBox="0 0 1374 525" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M485 352h32v-46h35c32 0 56-20 56-48 0-29-24-49-56-49h-67v143zm32-74v-41h31c16 0 27 8 27 21 0 12-11 20-27 20h-31zm112 74h53c50 0 86-29 86-71s-36-72-86-72h-53v143zm32-27v-88h25c29 0 49 18 49 44 0 25-20 44-49 44h-25zm246-88v-28H792v143h32v-56h75v-28h-75v-31h83zm76 118c35 0 53-19 53-41 0-26-21-35-49-42-25-6-40-11-40-26 0-13 14-25 32-25 15 0 30 7 43 19l10-13c-14-12-30-20-52-20-29 0-50 18-50 40 0 25 19 34 49 41 26 6 40 12 40 27 0 13-12 25-35 25-21 0-36-9-49-21l-10 13c15 14 34 23 58 23zm134-113c-16 0-30 9-37 20v-55h-16v145h16v-68c0-15 16-28 35-28 16 0 28 13 28 30v66h16v-68c0-24-17-42-42-42zm83-17c6 0 11-5 11-10 0-6-5-10-11-10s-11 4-11 10c0 5 5 10 11 10zm-8 127h16V245h-16v107zm74-122c0-8 6-14 12-14 5 0 10 2 12 5l8-12c-6-4-13-7-21-7-16 0-27 12-27 28v15h-18v14h18v93h16v-93h28v-14h-28v-15zm100 107c-3 2-7 4-12 4-6 0-12-6-12-14v-68h28v-14h-28v-30h-16v30h-18v14h18v68c0 17 11 28 27 28 8 0 15-3 21-7l-8-11zM341 304L219 147 205 0v525l14-157 122-64z" class="svg-fill"></path>
                                    <path d="M380 222l-28 80-53-66zM422 254l-36-28-10 33zM336 316l-1 15-103 38zM168 409h25v-23h-25zM173 321h20v19h-20zM170 348h23v22h-23zM127 449v-20h21v20zM110 444v-11h11v11zM120 424v-15h16v15zM117 458v-9h9v9zM136 388v-12h13v12zM147 365v-15h15v15zM182 304h11v10h-11zM165 269h28v26h-28zM172 242h21v20h-21zM176 220h17v16h-17zM164 206h10v10h-10zM159 246h9v8h-9zM174 181h19v18h-19zM151 179h16v15h-16zM141 134h21v20h-21zM166 160h16v16h-16zM180 144h13v12h-13zM22 118h13v12H22zM43 111h12v12H43zM68 116h24v23H68zM129 174h12v12h-12zM147 223h11v10h-11zM130 198h17v16h-17zM104 174h12v12h-12zM53 140h8v8h-8zM106 125h16v16h-16zM82 156h14v13H82zM19 139h8v8h-8zM29 152h16v16H29zM56 160h13v12H56zM52 179h9v9h-9zM67 192h9v8h-9zM81 189h17v17H81zM102 207h8v9h-8zM109 221h9v8h-9zM122 225h20v19h-20zM147 256h8v9h-8zM140 409v-9h9v9zM104 461v-5h6v5zM0 106h9v9H0zM160 425v-9h9v9zM156 400v-5h6v5zM108 148h17v17h-17z" class="svg-fill"></path>
                                </svg>
                            </a>
                        </div>
                        <nav id="header-main-menu" class="column">
                            <ul itemscope="">
                                <li>
                                    <a href="https://pdfshift.io/#features" title="Check out our features" itemprop="name" class="">Features</a>
                                </li> 
                                <li>
                                    <a href="https://pdfshift.io/#pricing" title="More on our pricing plans" itemprop="name" class="">Pricing</a>
                                </li>
                                <li>
                                    <a href="https://docs.pdfshift.io/" title="Read our documentation" itemprop="name" target="_blank">
                                        Docs
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" class="">
                                            <g>
                                                <path d="M23,2.5v8a.5.5,0,1,1-1,0V3.707L9.8536,15.8535a.5.5,0,0,1-.7072-.707L21.293,3H14.5a.5.5,0,0,1,0-1h8a.5.5,0,0,1,.5.5ZM20.5,14a.5.5,0,0,0-.5.5v9a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,1.5,5h9a.5.5,0,0,0,0-1h-9A1.5,1.5,0,0,0,0,5.5v18A1.5,1.5,0,0,0,1.5,25h18A1.5,1.5,0,0,0,21,23.5v-9A.5.5,0,0,0,20.5,14Z"></path>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://status.pdfshift.io/" title="View our running status" itemprop="name" target="_blank">
                                        Status page
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" class="">
                                            <g>
                                                <path d="M23,2.5v8a.5.5,0,1,1-1,0V3.707L9.8536,15.8535a.5.5,0,0,1-.7072-.707L21.293,3H14.5a.5.5,0,0,1,0-1h8a.5.5,0,0,1,.5.5ZM20.5,14a.5.5,0,0,0-.5.5v9a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,1.5,5h9a.5.5,0,0,0,0-1h-9A1.5,1.5,0,0,0,0,5.5v18A1.5,1.5,0,0,0,1.5,25h18A1.5,1.5,0,0,0,21,23.5v-9A.5.5,0,0,0,20.5,14Z"></path>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div id="header-main-cta" class="cta">
                            <div>
                                <a href="https://app.pdfshift.io" title="Login on PDFShift.io" itemprop="name">Login</a>
                                or
                                <a href="https://pdfshift.io/register/" title="Register now for free and start converting HTML to PDF!" class="column create-account">
                                    <svg viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.004 0c-5.523 0-10 4.478-10 10a9.87 9.87 0 0 0 .713 3.629L1.568 25.777C1.217 26.129 1 26.463 1 27v3c0 1.07.929 2 2 2h3c.536 0 .875-.215 1.226-.564L8.661 30h2.343a2 2 0 0 0 2-2v-2h2a2 2 0 0 0 2-2v-2.344l2.369-2.371c1.129.445 2.344.715 3.631.715 5.521 0 10-4.478 10-10s-4.479-10-10-10zm0 18c-1.48 0-2.852-.43-4.041-1.132l-.344.343-1.125 1.125-1.905 1.906a1.999 1.999 0 0 0-.586 1.414V24h-2a2 2 0 0 0-2 2v2H8.661a2 2 0 0 0-1.414.586l-1.418 1.418L3.003 30 3 27.15l11.665-11.644.001.002 1.469-1.469c-.702-1.189-1.132-2.56-1.132-4.04A8.001 8.001 0 1 1 23.004 18z" class="svg-fill"></path> <path d="M28.82 8.239a17.71 17.71 0 0 0-4.055-4.054.961.961 0 0 0-.882-.127c-1.389.489-2.34 1.439-2.826 2.828a.952.952 0 0 0 .127.882 17.736 17.736 0 0 0 4.053 4.053.966.966 0 0 0 .881.128c1.391-.486 2.342-1.438 2.83-2.828a.952.952 0 0 0-.128-.882zm-3 2.771A17.122 17.122 0 0 1 22 7.217c.387-1.103 1.111-1.827 2.182-2.221a16.75 16.75 0 0 1 3.816 3.811c-.391 1.095-1.113 1.815-2.178 2.203z" class="svg-fill"></path>
                                    </svg>
                                    Register
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-fluid">
            <main>
                <div class="row gx-5" :gutter="20" justify="space-between">
                    <form-view @update="onUpdateForm" ref="form" @submit="onSubmit" @save="onSave" />
                    <div class="col flex flex-column justify-content-between align-items-center border-start" style="height: calc(100vh - 80px);">
                        <div class="row h-50">
                            <codemirror v-model="code" placeholder="Code goes here..." :style="{ width: 'calc(100% - 12px)', 'max-width': 'calc(100% - 12px)' }" :indent-with-tab="true" :tab-size="2" :extensions="extensions" @change="onJsonChange" />
                        </div>
                        <div class="row h-50">
                            <div v-if="state === 'PENDING'" class="d-flex align-items-center justify-content-center">
                                Please hit the "Generate a PDF" button ...
                            </div>
                            <div v-else-if="state === 'SENDING'" class="d-flex align-items-center justify-content-center">
                                <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
                            </div>
                            <div v-else-if="state === 'ERROR'" class="gx-0 mt-3 pe-2">
                                <div class="alert alert-danger w-100" role="alert">
                                    <pre><code>{{ errors }}</code></pre>
                                </div>
                            </div>
                            <div v-else-if="state === 'JSON'" class="gx-0 mt-3 pe-2">
                                <div class="accordion">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#result-headers" aria-expanded="true" aria-controls="result-headers">
                                                Headers
                                            </button>
                                        </h2>
                                        <div id="result-headers" class="accordion-collapse collapse" :class="{'show': !body}">
                                            <div class="accordion-body">
                                                <div class="alert alert-success w-100" role="alert">
                                                    <pre><code>{{ headers }}</code></pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="body" class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#result-body" aria-expanded="false" aria-controls="result-body">
                                                Body
                                            </button>
                                        </h2>
                                        <div id="result-body" class="accordion-collapse collapse" :class="{'show': body}">
                                            <div class="accordion-body">
                                                <div class="alert alert-success w-100" role="alert">
                                                    <pre><code>{{ body }}</code></pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div class="modal fade" id="key-not-found" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Key not found</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-danger">
                            The key you tried to load was not found. Maybe it has expired or has been deleted.
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="new-url-modal" aria-hidden="true" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Key not found</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>
                                <span class="text-success">Your parameters has been saved. You can now share the following URL with your team:</span><br />
                                <small>(Your API key wasn't saved and won't be shared)</small>
                            </p>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" readonly :value="currentUrl" ref="targetUrl" />
                                <button class="btn btn-outline-secondary" :disabled="copied" :class="{'btn-disabled': copied}" type="button" @click="copyToClipboard">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212529" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormView from '@/components/FormView.vue'
import { Codemirror } from 'vue-codemirror'
import { json } from '@codemirror/lang-json'
import { oneDark } from '@codemirror/theme-one-dark'
import { createClient } from '@supabase/supabase-js'
const JSON5 = require('json5')


const supabaseUrl = 'https://wzyzaqqdtiqywigcsqal.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind6eXphcXFkdGlxeXdpZ2NzcWFsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODA2ODE2MzAsImV4cCI6MTk5NjI1NzYzMH0.lByMz3gffGwLFemWgxlNNmiUD6Z7uh2YiIqsdkgJfXg'

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

async function sha256 (message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message)

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

async function copyToClipboard (text) {
    if (!navigator.clipboard) {
        return new Promise((resolve, reject) => {
            const textArea = document.createElement("textarea")
            textArea.value = text

            // Avoid scrolling to bottom
            textArea.style.top = "0"
            textArea.style.left = "0"
            textArea.style.position = "fixed"

            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()

            let successful = false
            try {
                successful = document.execCommand('copy')
            } catch (err) {
                // pass
            }

            document.body.removeChild(textArea)
            if (successful) resolve()
            else reject()
        })
    }

    return await navigator.clipboard.writeText(text)
}


export default {
    components: { FormView, Codemirror },
    computed: {
        extensions () {
            return [json(), oneDark]
        },
        apiUrl () {
            try {
                const url = localStorage.getItem('apiUrl')
                if (url) return url
            } catch (e) {
                // pass
            }
            return 'https://api.pdfshift.io/v3/convert/pdf'
        }
    },
    watch: {
        state (to) {
            if (to === 'SENDING') {
                this.headers = null
                this.errors = null
                this.body = null
            } else {
                this.$refs.form.isSending = false
            }
        }
    },
    data () {
        return {
            code: '',
            state: 'PENDING',
            errors: null,
            headers: null,
            body: null,
            copied: false,
            currentUrl: null
        }
    },
    async mounted () {
        const el = document.getElementsByClassName("cm-content")[0]
        el.setAttribute("data-enable-grammarly", false)

        const key = window.location.pathname.substring(1)
        if (key) {
            const result = await supabase.from('queries').select('params').eq('key', key).limit(1).single()
            if (result.data) {
                this.currentUrl = window.location.href
                this.onUpdateForm(result.data.params)
                this.$refs.form.update(result.data.params)
            } else {
                // eslint-disable-next-line no-undef
                new bootstrap.Modal('#key-not-found').show()
                window.history.pushState('', '', `/`)
            }
        }
    },
    methods: {
        onJsonChange (value) {
            try {
                const data = JSON5.parse(value)
                this.$refs.form.update(data)
            } catch (e) {
                // pass
            }
        },
        onUpdateForm (data) {
            this.code = JSON.stringify(data, null, 2)
        },
        async onSubmit () {
            if (this.state === 'SENDING') return
            const form = this.$refs.form.form
            if (!form.source) {
                this.state = 'ERROR'
                this.errors = JSON.stringify({
                    success: false,
                    code: 0,
                    error: 'Please provide a valid source'
                }, null, 2)
                this.$refs.form.isSending = false
                return
            }
            this.state = 'SENDING'
            const headers = {
                'Content-Type': 'application/json',
            }
            
            if (this.$refs.form.apiKey) {
                headers['Authorization'] = 'Basic ' + btoa('api:' + this.$refs.form.apiKey)
            }

            const response = await fetch(this.apiUrl, {
                method: 'POST',
                headers,
                body: JSON.stringify(form)
            })

            this.headers = {
                'x-response-status-code': response.headers.get('x-response-status-code'),
                'x-credits-cost': response.headers.get('x-credits-cost'),
                'x-credits-remaining': response.headers.get('x-credits-remaining'),
                'x-credits-used': response.headers.get('x-credits-used'),
                'x-pdfshift-processor': response.headers.get('x-pdfshift-processor'),
                'x-server-identifier': response.headers.get('x-server-identifier'),
                'x-response-duration': response.headers.get('x-response-duration'),
                'x-pdfshift-duration': response.headers.get('x-pdfshift-duration'),
                'x-process-time': response.headers.get('x-process-time')
            }

            if (response.status >= 400) {
                this.state = 'ERROR'
                try {
                    this.errors = JSON.stringify(await response.json(), null, 2)
                } catch (e) {
                    this.errors = JSON.stringify({
                        code: 'unknown',
                        message: await response.text()
                    }, null, 2)
                }
            } else {
                this.state = 'JSON'
                if (response.headers.get('content-type') === 'application/json') {
                    this.body = JSON.stringify(await response.json(), null, 2)
                } else {
                    // Binary PDF!
                    const file = await response.blob()
                    const fileURL = URL.createObjectURL(file)
                    window.open(fileURL)
                }
            }
        },
        async onSave (params) {
            let key = '1000000000'.replace(/[018]/g, c => 
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            )

            // First, we check if it doesn't already exists
            const hashed = await sha256(JSON.stringify(params))
            const { data } = await supabase.from('queries').select('key').eq('hash', hashed).limit(1).single()
            if (data) {
                key = data.key
            } else {
                const { error } = await supabase.from('queries').insert({ key, params, hash: hashed })
                if (error) {
                    alert('An error occured...')
                    return console.log(error)
                }
            }

            window.history.pushState('', '', `/${key}`)
            this.currentUrl = window.location.href

            // eslint-disable-next-line no-undef
            new bootstrap.Modal('#new-url-modal').show()
        },
        copyToClipboard () {
            let tooltip = null
            this.copied = true
            try {
                copyToClipboard(this.currentUrl)
                // eslint-disable-next-line no-undef
                tooltip = bootstrap.Tooltip.getOrCreateInstance(this.$refs.targetUrl, {
                    title: 'Copied!',
                    trigger: 'manual'
                })
            } catch (e) {
                // eslint-disable-next-line no-undef
                tooltip = bootstrap.Tooltip.getOrCreateInstance(this.$refs.targetUrl, {
                    title: 'An error occurred',
                    trigger: 'manual'
                })
            }

            tooltip.show()
            setTimeout(() => {
                this.copied = false
                tooltip.dispose()
            }, 5000)
        }
    }
}
</script>

<style lang="scss">

$color-white: #fff;
$color-primary: #623e98;
$color-secondary: #523b7c;

.rrow {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

    .column {
        flex: 0 0 auto;
    }
}


::selection {
    background: $color-secondary; /* WebKit/Blink Browsers */
    color: #fff;
}

::-moz-selection {
    background: $color-secondary; /* Gecko Browsers */
    color: #fff;
}

html {
    scroll-behavior: smooth;
}

body {
    min-width: 325px;
}

h1 {
    color: $color-primary;
}

h2 {
    color: lighten($color-primary, 20%);
}

h3 {
    color: lighten($color-primary, 30%);
}

a {
    transition: border-bottom 250ms ease-in-out;
    border: none;
    border-bottom: solid 1px transparent;
    text-decoration: none;
    color: $color-secondary;
    font-weight: 400;

    &:hover {
        border-color: $color-secondary;
        text-decoration: none;
    }
}


a.button, input.button, button.button {
    display: inline-block;
    margin: 0 10px;
    padding: 20px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    outline: none;
    text-decoration: none;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;
    outline: none;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }

    &:active {
        box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.5)
    }

    &.button-primary {
        background-color: lighten($color-primary, 10%);
        color: $color-white;
        text-decoration: none;

        &:hover {
            background-color: $color-primary;
            border: none;
        }

        &.button-disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
                background-color: lighten($color-primary, 10%);
                color: $color-white;
            }
        }
    }

    &.button-secondary {
        text-decoration: none;
        background-color: $color-white;
        border: solid 1px $color-primary;
        border-radius: 5px;
        color: $color-primary;
        transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

        &:hover {
            background-color: $color-primary;
            text-decoration: none;
            border: solid 1px $color-primary;
            color: #fff;
        }

        &.button-disabled {
            opacity: 0.5;
            cursor: default;

            &:hover {
                background-color: lighten($color-primary, 10%);
                color: $color-white;
            }
        }
    }

    &.button-small {
        font-size: 0.8em;
        padding: 10px 20px;
    }
}

.page strong {
    font-weight: bold;
}

header {
    color: $color-white;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif;

    h1 {
        color: $color-white;
    }

    &.fixed-heading {
        margin-bottom: 40px;
        .heading {
            background-color: $color-primary;
            background-image: url('/public/images/trianglify.svg');
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            box-shadow: 0 5px 10px -5px #000;
        }
    }

    .heading {
        transition: box-shadow 250ms ease-in-out, background-color 250ms ease-in-out, background-image 250ms ease-in-out;

        &>div {
            div.column {
                flex: 0 0 160px;
                margin: 5px 0;
            }
            
            .accordion-menu { // XXX
                display: none;
                cursor: pointer;
                z-index: 99999;

                &>div {
                    width: calc(100% - 12px);
                    margin: 6px 6px;
                    height: 2px;
                    border-radius: 3px;
                    background: #fff;
                }
            }

            .brand {
                color: $color-white;
                text-decoration: none;
                border: none;
                margin: 10px 0;
                display: block;
                outline: none;

                svg {
                    width: 158px;
                    height: 60px;
                    fill: #fff
                }
            }

            nav {
                flex: 0 0 60%;
                margin-top: 20px;

                ul {
                    margin: 0;
                    text-align: center;

                    &>li {
                        display: inline-block;
                        padding: 12px 0;
                        margin: 0 5px;

                        a {
                            color: $color-white;
                            display: inline-block;
                            margin: 0 10px;
                            text-transform: uppercase;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 1px;
                            border-bottom: 1px solid transparent;
                            outline: none;

                            svg {
                                stroke: #fff;
                                width: 10px;
                                vertical-align: super;
                                stroke-width: 2px;
                            }

                            &:hover {
                                border-bottom-color: $color-white;
                            }
                        }
                    }
                }
            }

            .cta {
                flex: 0 0 auto;
                padding: 12px 0;
                margin-top: 20px;
                text-align: right;

                .hidden {
                    display: none;
                }

                a {
                    color: $color-white;
                    font-weight: 500;
                    font-style: italic;
                    transition: border 250ms ease-in-out, color 250ms ease-in-out, background-color 250ms ease-in-out;
                    margin: 0 5px;

                    &:last-child {
                        border: solid 1px $color-white;
                        border-radius: 5px;
                        padding: 10px 20px;
                        margin-right: 0;

                        &:hover {
                            background-color: #fff;
                            color: $color-primary;
                            border-color: $color-primary;
        
                            svg {
                                fill: $color-primary;
                            }
                        }
                    }
    
                    svg {
                        height: 16px;
                        width: 16px;
                        fill: #fff;
                        margin-right: 5px;
                        transition: fill 250ms ease-in-out;
                    }
                }
            }
        }
    }

    &::after {
        content: " ";
        clear: both;
        display: table;
    }
}

.spinner-border {
    --bs-spinner-animation-speed: 1.5s;
}

.cm-editor {
    max-height: calc(50vh - 40px)
}

.copy {
    cursor: pointer
}
</style>